<template>
    <v-container :class="isMobileSize() ? 'ma-0 pa-0' : null">
        <v-row>
            <v-col>
                <Store />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Store from '../components/Store.vue'

    export default {
        components: { 
            Store
        },
        
    }
</script>