<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step step="1">Select template base</v-stepper-step>
            <v-stepper-step step="2">Configure settings</v-stepper-step>
            <v-stepper-step step="3">Finalize configuration</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content 
                step="1"
                class="pa-0"
            >
                <v-card
                    rounded="0"
                >
                    <v-card-text>
                        <TemplateSelector />
                    </v-card-text>
                </v-card>
            </v-stepper-content>
            <v-stepper-content 
                step="2"
                class="pa-0"
            >
                <v-card
                    rounded="0"
                >
                    <v-card-text>
                        <VersionSelector class="pb-2"/>
                        <TemplateInputs />
                    </v-card-text>
                </v-card>
            </v-stepper-content>
            <v-stepper-content 
                step="3"
                class="pa-0"
            >
                <v-card
                    rounded="0"
                >
                    <v-card-text>
                        <v-form
                            ref="form"
                            lazy-validation
                        >
                            <v-text-field
                                v-model="configName"
                                label="Configuration name"
                                :disabled="isLoading"
                                outlined
                                :rules="[
                                    v => v.length > 0 || $t('General.required'),
                                    v => v.length <= 255 || $t('Rules.maxLength', [v.length, 255])
                                ]"
                            >
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-stepper-content>
        </v-stepper-items>
        <v-divider/>
        <v-stepper-header class="pa-4">
            <v-btn
                :disabled="step == 1 || isLoading"
                icon
                @click="previousStep()"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn
                :disabled="step == 3 || selectedTemplate == null"
                icon
                @click="nextStep()"
            >
                <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <v-spacer/>
            <v-fade-transition>
                <v-btn
                    v-if="step == 3"
                    class="primary"
                    @click="createConfig"
                    :disabled="isLoading"
                >
                    Create configuration
                </v-btn>
            </v-fade-transition>
        </v-stepper-header>
    </v-stepper>      
</template>

<script>

import VersionSelector from '@/components/VersionSelector'
import TemplateInputs from '@/components/TemplateInputs'
import TemplateSelector from '@/components/TemplateSelector'
import apiClient from '@/api/apiClient'
import createDefaultConfig from '@/utils/createDefaultConfig'
import { forOwn } from 'lodash'

export default {
    components: {
        VersionSelector,
        TemplateInputs,
        TemplateSelector
    },
    data: () => ({
        step: 1,
        configName: "",
        isLoading: false        
    }),
    methods: {
        nextStep() {
            if(this.step < 3) {
                this.step++
            }
        },
        previousStep() {
            if(this.step > 1) {
                this.step--
            }
        },
        createConfig() {
            if(!this.$refs.form.validate()) return
            
            let config = createDefaultConfig(this.$store.getters.features)
            let actualConfig = this.$store.getters.config.actual
            let $store = this.$store
            forOwn(actualConfig, function(feature, featureName){
                forOwn(feature, function(property, propertyName) {
                    if(typeof property == 'object') {
                        // if property is a property group
                        let propertyGroup = property
                        let propertyGroupName = propertyName
                        forOwn(propertyGroup, function(property, propertyName) {
                            this.$store.getters.activeOrganizationId
                            config[featureName][propertyGroupName][propertyName] = $store.getters.property(featureName, propertyGroupName, propertyName)
                        })
                    }
                    else {
                        // if property is just a property
                        config[featureName][propertyName] = $store.getters.property(featureName, null, propertyName)
                    }
                })
            })
            
            let body = {
                name: this.configName,
                organization: `/organizations/${this.$store.getters.activeOrganizationId}`,
                configuration: config
            }
            this.isLoading = true
            apiClient.post('/device-configuration-templates', body)
                .then(response => {
                    this.selectedTemplate = null
                    let configId = response.data.id
                    this.$router.push(`/template/${configId}`)
                })
                .catch(e => {
                    // TODO snackbar if save failed
                    console.log(e)
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    },
    computed: {
        selectedTemplate: {
            get() {
                return this.$store.getters.template
            },
            set(template) {
                this.$store.dispatch('setTemplate', template)
            }
        }
    },
    watch: {
        selectedTemplate() {
            this.step = 2
        }
    }
}
</script>