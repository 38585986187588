<template>
    <div>
        <div v-if="!categories || !templates">
            <v-progress-circular
                class="py-10 d-flex mx-auto"
                indeterminate
                color="primary"
            />
        </div>
        <v-expansion-panels v-else>
            <v-expansion-panel
                v-for="[categoryName, category], index in Object.entries(categories)" :key="index"
            >
                <v-expansion-panel-header>
                    <v-row>
                        <v-col cols="auto" class="px-1">
                            <v-icon dense>{{ category.icon }} </v-icon>
                        </v-col>
                        <v-col class="my-auto">
                            <span>{{ translate(category.title)  }}</span>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col 
                            v-for="template, index in templates.filter(template => template.category == categoryName)" :key="index"
                            cols="6" md="4" lg="3"
                        >
                            <v-card
                                max-width="250"
                                height="100%"
                                @click="selectedTemplate = template"
                                outlined
                                :raised="(selectedTemplate && selectedTemplate.id == template.id)"
                                class="d-flex flex-column"
                            >
                                <v-scroll-y-transition>
                                    <v-overlay
                                        v-if="fullyExpandedTemplateIds.includes(template.id)"
                                        opacity="0.75"
                                        absolute
                                        @click.stop="toggleFullytoggleExpandTemplate(template.id)"
                                    >
                                        <div class="ma-4">
                                            <p>{{ translate(template.description) }}</p>
                                        </div>
                                    </v-overlay>
                                </v-scroll-y-transition>
                                
                                <v-img
                                    height="150"
                                    max-height="150"
                                    :src="template.image_url ? template.image_url : categories[template.category].placeholder_image_url"
                                    @error="template.image_url=categories[template.category].placeholder_image_url"
                                />
                                <v-divider/>
                                <v-card-title class="text-wrap pb-0" style="word-break: break-word" >{{ translate(template.title) }}</v-card-title>
                                <v-spacer/>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click.stop="toggleExpandTemplate(template.id)"
                                    >
                                        {{ expandedTemplateIds.includes(template.id) ? "Hide info" : "Show info"}}
                                    </v-btn>
                                </v-card-actions>
                                <v-expand-transition>
                                    <v-card
                                        v-if="expandedTemplateIds.includes(template.id)"
                                    >
                                        <v-card-text>{{ translate(template.summary) }}</v-card-text>
                                        <v-card-actions class="">
                                            <v-btn
                                                text
                                                color="primary"
                                                @click.stop="toggleFullytoggleExpandTemplate(template.id)"
                                            >
                                                More info
                                            </v-btn>
                                        </v-card-actions>
                                        <v-card-text>
                                            Firmware requirements:
                                            <div v-for="(version, name, index) in template.min_version" :key="index">
                                                {{ `${name.toUpperCase()}: ${version}` }}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
    
<script>
import { cloneDeep } from 'lodash'

export default {
    data: () => ({
        placeholderImageUrl: "https://www.iotracker.nl/uploads/images/8/1/8149332a3a11858930889a1279239318d2c9affb/header/7d4290/emergency-and-safety.jpg",
        expandedTemplateIds: [],
        fullyExpandedTemplateIds: [],
    }),
    methods: {
        toggleExpandTemplate(templateId) {
            let index = this.expandedTemplateIds.indexOf(templateId)
            if(index == -1) {
                this.expandedTemplateIds.push(templateId)
                return
            }
            this.expandedTemplateIds.splice(index, 1)
        },
        toggleFullytoggleExpandTemplate(templateId) {
            let index = this.fullyExpandedTemplateIds.indexOf(templateId)
            if(index == -1) {
                this.fullyExpandedTemplateIds.push(templateId)
                return
            }
            this.fullyExpandedTemplateIds.splice(index, 1)
        },
        editTemplate(templateId) {
            // TODO:
            console.log("Open editor for template with ID: " + templateId)
        },
    },
    computed: {
        categories() {
            return this.$store.getters.storeCategories
        },
        templates() {
            return this.$store.getters.templates
        },
        selectedTemplate: {
            get() {
                return this.$store.getters.template
            },
            set(template) {
                this.$store.dispatch('setTemplate', template)
                if(this.$route.name == 'store') {
                    this.$store.dispatch('setTemplateInSetup', cloneDeep(template.config))
                }
                else if(template) {
                    let config = template.config
                    Object.keys(config).forEach(featureName => {
                        let feature = config[featureName]
                        Object.keys(feature).forEach(key => {
                            if(typeof feature[key] == 'object') {
                                let propertyGroupName = key
                                let propertyGroup = feature[key]
                                Object.keys(propertyGroup).forEach(propertyName => {
                                    let newValue = propertyGroup[propertyName]
                                    let payload = {
                                        featureName,
                                        propertyGroupName,
                                        propertyName,
                                        newValue
                                    }
                                    this.$store.dispatch("setProperty", payload)
                                })
                            }
                            else {
                                let propertyName = key
                                let newValue = feature[propertyName]
                                let payload = {
                                    featureName,
                                    propertyGroupName: null,
                                    propertyName,
                                    newValue
                                }
                                this.$store.dispatch("setProperty", payload)

                            }
                        })
                    }) 
                    
                    
                }
            }
        }
    }
}
</script>

<style>

</style>