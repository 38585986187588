<template>
    <div v-if="template">
        <v-progress-circular
            v-if="!specIsFetched"
            class="py-10 d-flex mx-auto"
            indeterminate
            color="primary"
        />
        <div v-else v-for="names, index in namesList" :key="index">
            <div v-if="names.title">
                <v-divider 
                    v-if="index != 0"
                    class="my-2"
                />
                <h2 
                    class="py-2 pb-4"
                >
                    {{names.title}}
                </h2>
            </div>
            <h4
                v-else-if="names.subtitle"
                class="pb-2 pt-0"
            >
                {{names.subtitle}}
            </h4>
            <GenerativeInput
                v-else
                :featureName="names.featureName"
                :propertyGroupName="names.propertyGroupName"
                :propertyName="names.propertyName"
                :property="getPropertySpec(names.featureName, names.propertyGroupName, names.propertyName)"
                :previousSubheader="getPreviousSubheader(names.featureName, names.propertyGroupName, names.propertyName)"
            />
        </div>
    </div>
</template>

<script>

import GenerativeInput from '@/components/GenerativeInput'

export default {
    components: {
        GenerativeInput,
    },
    methods: {
        getPropertySpec(featureName, propertyGroupName, propertyName) {
            return this.$store.getters.propertySpec(featureName, propertyGroupName, propertyName)
        },
        getFeatureTitle(featureName) {
            return this.translate(this.$store.getters.features[featureName].title)
        },
        getPropertyGroupTitle(featureName, propertyGroupName) {
            return this.translate(this.$store.getters.features[featureName].property_groups[propertyGroupName].title)
        },
        getPreviousSubheader(featureName, propertyGroupName, propertyName) {
            let previousIndex = this.namesList.findIndex(property => (property.propertyName == propertyName && property.propertyGroupName == propertyGroupName && property.featureName == featureName)) - 1
            if(previousIndex < 0) return undefined
            let previousNames = this.namesList[previousIndex]
            if(previousNames.title) return undefined
            return this.getPropertySpec(previousNames.featureName, previousNames.propertyGroupName, previousNames.propertyName).subheader
        }
    },
    computed: {
        specIsFetched() {
            return this.$store.getters.spec != null
        },
        template() {
            return this.$store.getters.template
        },
        namesList() {
            if(!this.template) {
                return []
            }
            let namesList = []
            let config = this.template.config

            for (const [featureName, feature] of Object.entries(config)) {
                namesList.push({
                    title:this.getFeatureTitle(featureName)
                })
                for (const [propertyNameOrPropertyGroupName, propertyOrPropertyGroup] of Object.entries(feature)) {
                    if(typeof propertyOrPropertyGroup == 'object') {
                        namesList.push({
                            subtitle: this.getPropertyGroupTitle(featureName, propertyNameOrPropertyGroupName)
                        })
                        Object.keys(propertyOrPropertyGroup).forEach(propertyName => {
                            namesList.push({
                                featureName,
                                propertyGroupName: propertyNameOrPropertyGroupName,
                                propertyName
                            })
                        })
                    }
                    else {
                        namesList.push({
                            featureName, 
                            propertyGroupName: null,
                            propertyName: propertyNameOrPropertyGroupName
                        })
                    }
                }
            }
            return namesList
        }
    }
}
</script>